import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';
import type * as UserModule from 'o365.pwa.declaration.shared.dexie.objectStores.User.d.ts';

export class User implements UserModule.User {
    static objectStoreDexieSchema: string = "&id";

    id: number;
    userSession: any;

    constructor(id: number, userSession: any) {
        this.id = id;
        this.userSession = userSession;
    }

    public async save(): Promise<void> {
        await IndexedDbHandler.updateUser(this);
    }

    public async delete(): Promise<void> {
        await IndexedDbHandler.deleteUser(this);
    }

    public async forceReload(): Promise<User | null> {
        return await IndexedDbHandler.getUserFromIndexedDB();
    }
}

export default User;
